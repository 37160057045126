import logo from './logo.svg';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useRef } from 'react';
import { Analytics } from '@vercel/analytics/react';

function App() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
    window.addEventListener('resize', updateSize);
  }, []);

  const updateSize = () => {

  };

  const redirectToTelegram = () => {
    window.location.href = 'https://onlyfans.com/misschloewelsh';
  };

  return (
    <div className="App">

      <header className="header">
        <div className="row">
          <div className="spacer"></div>
          <h1 className='name'>Chloe Welsh</h1>
          <div className="header_button">
            <button className="onlyfans_button" onClick={redirectToTelegram}>JOIN HERE</button>
          </div>
        </div>

        <div className="title">
          <div className="status"> Top 0% creator on OnlyFans </div>
          <h1 className="main_text">Did Someone Say <br /> <p className="main_text_color">OnlyFans?</p></h1>
          <button className="onlyfans_button join" onClick={redirectToTelegram}>JOIN HERE</button>
        </div>

      </header>

      <div className="percs">
        <div className="perc_item">
          <div className="perc_image"></div>
          <div className="perc_text">
            New Content Added Daily
          </div>
        </div>

        <div className="perc_item">
          <div className="perc_image"></div>
          <div className="perc_text">
            Order Custom Content
          </div>
        </div>

        <div className="perc_item">
          <div className="perc_image"></div>
          <div className="perc_text">
            Buy my Nylons & Panties
          </div>
        </div>

        <div className="perc_item">
          <div className="perc_image"></div>
          <div className="perc_text">
            Chat with me
          </div>
        </div>
      </div>

      <div className="gallery">

      <div className="button-container">
          <button className="onlyfans_button gallery_join" onClick={redirectToTelegram}>JOIN HERE</button>
        </div>
        <div className="status"> SELFIES & MUCH MORE! </div>
        <h1 className="gallery_text">Want access to my <br /> <p className="gallery_text_color">phones pics & vids?</p></h1>



        <div className="gallery_images">
          <div className="float_container" data-aos="slide-right">
            <div className="gallery_image" data-aos="slide-up"></div>
          </div>

          <div className="float_container" data-aos="slide-up">
            <div className="gallery_image"></div>
          </div>

          <div className="float_container" data-aos="slide-up">
            <div className="gallery_image"></div>
          </div>

          <div className="float_container" data-aos="slide-up">
            <div className="gallery_image"></div>
          </div>

          <div className="float_container" data-aos="slide-left">
            <div className="gallery_image" data-aos="slide-up"></div>
          </div>
        </div>
      </div>

      <div className='flip_images'>
        <div className="flip_container" data-aos="slide-up">
          <div className="flip_card card_left">
            <div className="flip_card_front"></div>
            <div className="flip_card_back"></div>
          </div>
          <div className='flip_div'>
            <h3 className="flip_title">Talk To Me In The DM’s</h3>
            <p className="flip_text">My OF is the only place where I reply to every message I receive 🙂</p>
            <button className='onlyfans_button flip_button' onClick={redirectToTelegram}>JOIN HERE</button>
          </div>
        </div>

        <div className="flip_container" data-aos="slide-up">
          <div className="flip_card card_right">
            <div className="flip_card_front"></div>
            <div className="flip_card_back"></div>
          </div>
          <div className='flip_div'>
            <h3 className="flip_title">1-2-1 Private Chats!</h3>
            <p className="flip_text">I run my entire page myself so you will always be speaking to me personally!!!</p>
            <button className='onlyfans_button flip_button' onClick={redirectToTelegram}>JOIN HERE</button>
          </div>
        </div>
      </div>

      <div className='reviews gallery'>
        <div className="status"> WHAT MY FOLLOWERS ARE SAYING </div>
        <h1 className="review_text gallery_text">I love getting feedback <br /> <p className="gallery_text_color"> from you! </p></h1>

        <div className='reviews_container'>
          <div className='review'>
            <img className='review_image' src='https://i.ibb.co/XXrBN21/photo-2024-05-24-18-12-10.jpg' alt='Review 1' />
          </div>
          <div className='review'>
            <img className='review_image' src='https://i.ibb.co/dBvDPr1/photo-2024-05-24-18-12-09.jpg' alt='Review 2' />
          </div>
          <div className='review'>
            <img className='review_image' src='https://i.ibb.co/t8Sn95W/photo-2024-05-24-18-12-04.jpg' alt='Review 3' />
          </div>
        </div>
      </div>

      <div className='action'>
        <div className='waves'>
          <div className='wave1'>
            <svg className='wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none">
              <path fill="#11A3EB" d="M0 0L2600 0 2600 69.1 0 0z"></path>
              <path fill="#11A3EB" style={{ opacity: "0.5" }} d="M0 0L2600 0 2600 69.1 0 69.1z"></path>
              <path fill="#11A3EB" style={{ opacity: "0.25" }} d="M2600 0L0 0 0 130.1 2600 69.1z"></path>
            </svg>
          </div>
          <div className='wave2'>
            <svg className='wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none">
              <path fill="#11A3EB" style={{ opacity: "0.3" }} d="M0 0L2600 0 2600 69.1 0 0z"></path>
              <path fill="#11A3EB" style={{ opacity: "0.5" }} d="M0 0L2600 0 2600 69.1 0 69.1z"></path>
              <path fill="#11A3EB" style={{ opacity: "0.25" }} d="M2600 0L0 0 0 130.1 2600 69.1z"></path>
            </svg>
          </div>
        </div>
        <div className='action_container'>
          <div className='action_box'>
            <div className='action_square'>
              <div className='action_image'> </div>
            </div>
            <div className='action_tex'>
              <h1 className="action_title gallery_text"> Join Now <br /> Stop Thinking! <br /> <p className="action_text_color"> Come & Say Hi! </p></h1>
              <button className='onlyfans_button flip_button' onClick={redirectToTelegram}>JOIN HERE</button>
            </div>
          </div>
        </div>
      </div>

      <div className='footer'>
        <h1 className='name'>Chloe Welsh</h1>
      </div>
      <Analytics />
    </div>
  );
}

export default App;
